import React from 'react'
import {Chart as ChartJs, 
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js'

import {Line} from 'react-chartjs-2'
import styled from 'styled-components'
import { useGlobalContext } from '../../context/globalContext'
import { dateFormat } from '../../utils/dateFormat'
import moment from 'moment';

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
)

function Chart() {
    const {incomes} = useGlobalContext()
    let map = new Map();
    let mapprime = new Map();
    incomes.forEach(income => {
        if(map.has(income.date)){
            map.set(income.date, map.get(income.date) + income.amount);
            mapprime.set(income.date, mapprime.get(income.date) + income.amountprime);
        }else{
            map.set(income.date, income.amount);
            mapprime.set(income.date, income.amountprime);
        }
    });
    map = new Map([...map].sort((a, b) => moment(a[0]).isBefore(moment(b[0]))));
    mapprime = new Map([...mapprime].sort((a, b) => moment(a[0]).isBefore(moment(b[0]))));
    const data = {
        labels: Array.from(map.keys()).map((date) =>{
            return moment(date).format("DD MMM YYYY")
        }),
        datasets: [
            {
                label: 'Recup',
                data: [
                    ...Array.from(map.values())
                ],
                backgroundColor: 'green',
                tension: .2
            },
            {
                label: 'Prime',
                data: [
                    ...Array.from(mapprime.values())
                ],
                backgroundColor: 'red',
                tension: .2
            }
        ]
    }


    return (
        <ChartStyled >
            <Line data={data} />
        </ChartStyled>
    )
}

const ChartStyled = styled.div`
    background: #FCF6F9;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    padding: 1rem;
    border-radius: 20px;
    height: 100%;
`;

export default Chart